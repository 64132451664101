<template>
  <div>
    <div class='main-page-content'>
      <!--      <el-page-header @back='$router.go(-1)' content='详情页面'></el-page-header>-->
      <el-card class='box-card'>
        <div slot='header' class='clearfix'>
          <div style='width: 100%;text-align: center;font-size: 24px;font-weight: bold'>
            {{ this.$utils.parseTime(this.info.start_at, '{y}年{m}月{d}日') }}
            【{{ this.info.nickname }}】{{ this.info.platform_name }} 直播需求详情 <label
            :style='`font-size:14px;text-align: center;border-radius: 5px;padding:2px 8px;margin:1px;border: #7d8086 1px solid;background-color:${progressStatusColorMap[info.progress_status]}`'>{{ progressStatusAliasMap[info.progress_status] || '--'
            }}</label>
          </div>
        </div>
        <div>
          <div v-if='id'>
            <el-descriptions title='基本信息'>
              <template slot='title'>
                基本信息
                <el-link type='primary' icon='el-icon-edit-outline' @click='handleEdit(info)' :disabled='!canEdit'
                         v-if='userPermissions.indexOf("lb_schedule_edit")>-1'
                         style='margin-left: 4px;font-size: 1.1em'></el-link>
              </template>
              <el-descriptions-item label='红人昵称'>{{ info.nickname }}</el-descriptions-item>
              <el-descriptions-item label='直播平台'>{{ info.platform_name }}</el-descriptions-item>
              <el-descriptions-item label='直播主题'>
                {{ info.theme }}
              </el-descriptions-item>
              <el-descriptions-item label='直播时间'> {{ $utils.parseTime(info.start_at, '{y}年{m}月{d}日 {h}:{i}') }} 至
                {{ $utils.parseTime(info.end_at, '{y}年{m}月{d}日 {h}:{i}') }}
              </el-descriptions-item>
              <el-descriptions-item label='坑位数量'>{{ info.pit_num }}</el-descriptions-item>
              <el-descriptions-item label='货品结构'>{{ info.product_structure }}</el-descriptions-item>
              <el-descriptions-item label='最晚选品初选时间'>{{ $utils.parseTime(info.deadline, '{y}年{m}月{d}日') }}
              </el-descriptions-item>
              <el-descriptions-item label='负责人'>{{ info.leader_name }}</el-descriptions-item>
              <el-descriptions-item label='备注'>{{ info.remark }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </el-card>
        <div v-if='userPermissions.indexOf("lb_schedule_log")>-1&&id'>
          <el-divider content-position='left'>
            <el-link>日志</el-link>
          </el-divider>
          <LogList ref='refLog' :info-id='id' />
        </div>
    </div>


    <!--    创建直播排期-->
    <CreateEdit :visible.sync='open' :id='id' @close='getInfo' />

    <!--    发起需求-->
    <CommitDemandConfirm :visible.sync='openDemandConfirm' :id='id' />

    <!--    导入需求明细-->
    <ImportDemand :visible.sync='openImport' :id='id' @imported='showLastDemand' />
  </div>
</template>

<script>


import CreateEdit from './CreateEdit'
import LbScheduleLog from '@/pages/lb/LbScheduleLog'
import LogList from '@/pages/lb/components/LogList'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import ImportDemand from '@/pages/lb/components/ImportDemand'
import LbDemandList from '@/pages/lb/components/LbDemandList'
import CommitDemandConfirm from '@/pages/lb/components/CommitDemandConfirm'
import SelectionAckCard from '@/pages/lb/selection_ack/SelectionAckCard'
import SelectionKol from '@/pages/lb/kol/SelectionKol'

export default {
  name: 'ScheduleDetail',
  components: {
    SelectionKol,
    SelectionAckCard,
    CommitDemandConfirm,
    LbDemandList,
    ImportDemand,
    ExportBtn,
    LogList,
    LbScheduleLog,
    CreateEdit
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    canEdit() {
      return (this.userPermissions.indexOf('lb_schedule_edit_leader') > -1 || this.info.leader_id === this.userInfo.id)
    },
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.class_name
      })
      return map
    },
    progressStatusAliasMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.label
      })
      return map
    },
    progressStatusColorMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.color_value
      })
      return map
    }
  },
  data() {
    return {
      id: null,
      info: {},
      dataList: [],
      isTable: false,
      progress_status: 1,
      open: false,
      openImport: false,
      openDemandConfirm: false,
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      showSelectionCard: false


    }
  },
  methods: {
    init() {
      this.getInfo()
    },
    async getInfo() {
      let { info } = await this.$api.getLbScheduleInfo(this.id)
      this.info = info
      // console.log(info.type)
      if([1,2].indexOf(info.type)===-1){
        console.log(info.type)
        this.$notify.warning("未设置排期类型，请修改基本信息，设置排期类型")
        // this.handleEdit()
      }
      this.progress_status = info.progress_status
      this.$nextTick(() => {
        this.dataList = [info]
        // console.log(this.dataList)
      })
    },
    handleEdit() {
      this.open = true
    },
    async commitDemand() {
      this.openDemandConfirm = true
      // await this.$api.launchLbSchedule(this.id)
      // await this.init()
    },
    jumpToPage(pageName, query) {
      this.$router.push({
        name: pageName,
        query: query
      })
      // const { href } = this.$router.resolve({
      //   name: pageName,
      //   query: query
      // })
      // window.open(href, '_blank')
    },
    async exportDemandExcel() {
      try {
        let name = `直播排期需求明细表-${this.info.nickname}-${this.$utils.parseTime(this.info.start_at, '{y}{m}{d}')}`
        this.$notify.info(name)
        let queryParams = {
          lb_id: this.id
        }
        let response = await this.$api.exportLbScheduleDemand(queryParams)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    //打开导入模态框
    openImportDialog() {
      //dialog
      this.openImport = true
    },
    showHistory() {
      if (this.$refs['refLbDemandList'])
        this.$refs['refLbDemandList'].showHistory()
    },
    showLastDemand() {
      if (this.$refs['refLbDemandList'])
        this.$refs['refLbDemandList'].geLbDemandList()
    }
  },
  mounted() {

  },
  created() {
    this.id = this.$route.params.id || this.$route.query.id
    this.getInfo()
  }
}
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.box-progress {
  width: 90%;
  padding: 10px;
  height: 100px;
  /*line-height: 100px;*/
  border: 1px solid #000c17;
  border-radius: 5px;
  /*margin-bottom: 10px;*/
  line-height: 100px;
}

.box-progress-status {
  border: #1e1e33 1px solid;
  border-radius: 5px;
  min-width: 10% !important;
  width: 20%;
  max-width: 20% !important;
  line-height: 10px;
}

.required-dot {
  color: #ff0020;
  margin-right: 5px;
}

.link {
  cursor: pointer;
}

.box-padding {
  padding: 3px 5px 1px;
  color: black;
}

.status-new {
  background-color: #FFFFFF;
  color: #000c17;
}

.status-launch {
  background-color: #67C23A;
  color: #fefefe;
}

.status-original {
  background-color: #409EFF;
  color: #fefefe;
}

.status-ack {
  background-color: #E6A23C;
  color: #fefefe;
}

.status-lock {
  background-color: #F56C6C;
  color: #fefefe;
}
</style>
